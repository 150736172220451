const profileSecurityPassword = {
    data() {
        return {
            isEditPass: false,
            isSavingPassword: false,
            placeholderForPassword: '***********',
            isEmptyDataPassword: false,
            passButtonText: 'Сохранить'
        }
    },

    methods: {
        editPass() {
            this.isEditPass = true;
            this.placeholderForPassword = 'Введите новый пароль';

            this.$nextTick(() => {
                this.$refs.password.focus()
            })
        },

        savePass() {
            this.isSavingPassword = true;

            if (!this.$refs.password.value) {
                setTimeout(() => {
                    this.$refs.password.focus();
                }, 0)

                return null
            }

            this.showSpinner = true;
            let data = {
                type: 'password',
                value: this.$refs.password.value
            };

            this.UPDATE_USER_SECURITY_INFO(data).then(() => {
                this.showSpinner = false
                this.$refs.password.value = ''
                this.placeholderForPassword = '***********';
                this.passButtonText = '&#10004;&nbsp;Сохранено'
                setTimeout(() => {
                    this.isEditPass = false
                    this.passButtonText = 'Сохранить'
                }, 2000)
            })
        },

        onFocusPassword() {
            this.isSavingPassword = false;
        },

        onBlurPassword() {
            if (!this.isSavingPassword) {
                this.$refs.password.value = '';
                this.isEditPass = false;
            }
        },
    }
}

export default profileSecurityPassword
