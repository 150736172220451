const profileSecurityEmail = {
    data() {
        return {
            isEditEmail: false,
            isSavingEmail: false,
            placeholderForEmail: 'Введите новый Email',
            isEmptyDataEmail: false,
            mailButtonText: 'Сохранить'
        }
    },

    methods: {
        editEmail() {
            this.isEditEmail = true;

            this.$nextTick(() => {
                this.$refs.email.focus()
            })
        },

        saveEmail() {
            this.isSavingEmail = true;

            if (!this.$refs.email.value) {

                this.isEmptyDataEmail = true;
                this.placeholderForEmail = 'Введите данные!';

                setTimeout(() => {
                    this.isEmptyDataEmail = false;
                    this.placeholderForEmail = 'Введите новый Email';
                    this.$refs.email.focus();
                }, 600)

                return null
            }

            this.showSpinner = true;
            let data = {
                type: 'email',
                value: this.$refs.email.value
            };

            this.UPDATE_USER_SECURITY_INFO(data).then(() => {
                this.showSpinner = false
                this.mailButtonText = '&#10004;&nbsp;Сохранено'
                setTimeout(() => {
                    this.isEditEmail = false
                    this.mailButtonText = 'Сохранить'
                }, 2000)
            });
        },

        onBlurEmail() {
            if (!this.isSavingEmail) {
                this.$refs.email.value = '';
                this.isEditEmail = false;
            }
        },

        onFocusEmail() {
            this.isSavingEmail = false;
        },
    }
}

export default profileSecurityEmail